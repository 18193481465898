import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import ImageType from "../../../../../model/ImageType"
import GImage from "../../../../../../gatsby/GImage"
import { primaryLight, white } from "../../../../../constants/colors"
import Subheading from "../../../Subheading"
import { minionFont } from "../../../../../constants/fonts"
import { largeDesktop, mobile, smallMobile, tablet } from "../../../../../constants/screenSizes"
import BorderedButton from "../../../../button/BorderedButton"
import { PrimaryButton } from "social-supermarket-components"

const Container = styled.div`
  height: 500px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.5;
  z-index: 1;
`
const Content = styled.div`
  color: white;
  width: 100%;
  max-width: 1200px;
  z-index: 3;

  @media (max-width: ${largeDesktop}px) {
    padding: 0 30px;
  }
`
const BackgroundImage = styled(GImage)`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
`
const MainTitle = styled.h1`
  margin: 20px 0;
  font-family: ${minionFont};
  font-size: 48px;
  color: ${white};

  @media (max-width: ${tablet}px) {
  }
`
const ButtonSection = styled.div`
  display: flex;

  @media (max-width: ${mobile}px) {
    flex-direction: column;
  }
`
const StyledPrimaryButton = styled(PrimaryButton)`
  margin-right: 10px;

  @media (max-width: ${mobile}px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`

interface Props {
  image: ImageType
  heading: string
  subHeading: string
  action: string
  onAction: () => void
  secondaryAction?: string
  onSecondaryAction?: () => void
}

const LPBanner1: FC<Props> = ({
  image,
  heading,
  subHeading,
  action,
  onAction,
  secondaryAction,
  onSecondaryAction,
}) => {
  return (
    <Container>
      <Content>
        <Subheading isBordered color={white} borderColor={primaryLight}>
          {subHeading}
        </Subheading>
        <MainTitle>{heading}</MainTitle>
        <ButtonSection>
          <StyledPrimaryButton onClick={onAction}>{action}</StyledPrimaryButton>
          {secondaryAction && (
            <BorderedButton onClick={onSecondaryAction}>{secondaryAction}</BorderedButton>
          )}
        </ButtonSection>
      </Content>
      <Overlay />
      <BackgroundImage image={image} />
    </Container>
  )
}

export default LPBanner1
